import React, { createContext, useState } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || navigator.language.slice(0, 2);
  });

  const handleSelectLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, handleSelectLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;