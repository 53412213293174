import React, { useContext } from "react"; // Importeer useContext
import Logo from "./Logo";
import LanguageContext from "../context/LanguageContext";
import LanguageSelector from "./LanguageSelector";
import ThemeSwitcher from "./ThemeSwitcher";
import CONTENT_EN from "../data/content_en";
import CONTENT_FR from "../data/content_fr";
import CONTENT_NL from "../data/content_nl";

export default function NavigationBar() {
  const { language } = useContext(LanguageContext);
  let content;
  switch (language) {
    case "fr":
      content = CONTENT_FR;
      break;
    case "nl":
      content = CONTENT_NL;
      break;
    default:
      content = CONTENT_EN;
  }
  return (
    <div className="nav--section">
      <div className="nav--bar content">
        <div className="logo--group">
          <Logo /> <h1>{content.titel}</h1>
        </div>
        <div className="utility--bar">
          <ThemeSwitcher />
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
}
