import React, { useState, useEffect, useContext, useCallback } from "react";
import LanguageContext from "../context/LanguageContext";
import COUNTDOWN_EN from "../data/countdown/countdown_en";
import COUNTDOWN_FR from "../data/countdown/countdown_fr";
import COUNTDOWN_NL from "../data/countdown/countdown_nl";
import CONTENT_FR from "../data/content_fr";
import CONTENT_EN from "../data/content_en";
import CONTENT_NL from "../data/content_nl";

export default function Countdown({ targetDate }) {
  const { language } = useContext(LanguageContext);

  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [calculateTimeLeft, timeLeft]);

  const getContent = (language) => {
    switch (language) {
      case "fr":
        return CONTENT_FR;
      case "en":
        return CONTENT_EN;
      default:
        return CONTENT_NL;
    }
  };

  const content = getContent(language);

  const getCountdownText = (language) => {
    switch (language) {
      case "fr":
        return COUNTDOWN_FR;
      case "nl":
        return COUNTDOWN_NL;
      default:
        return COUNTDOWN_EN;
    }
  };

  const countdownText = getCountdownText(language);

  const formatNumber = (num) => (num < 10 ? `0${num}` : num.toString());

  return (
    <div className="countdown--section">
      <div className="content">
        <h1>{content.hou_de_datum_vrij}</h1>
        <p>{content.datum}</p>
        <h2>{countdownText.titel}</h2>
        <div className="countdown--container">
          <CountdownItem value={timeLeft.days} label={countdownText.dagen} />
          <CountdownItem
            value={formatNumber(timeLeft.hours)}
            label={countdownText.uren}
          />
          <CountdownItem
            value={formatNumber(timeLeft.minutes)}
            label={countdownText.minuten}
          />
          <CountdownItem
            value={formatNumber(timeLeft.seconds)}
            label={countdownText.seconden}
          />
        </div>
      </div>
    </div>
  );
}

function CountdownItem({ value, label }) {
  return (
    <div className="countdown--item">
      <div>
        <span className="countdown--value">{value}</span>
      </div>
      <span className="countdown--label">{label}</span>
    </div>
  );
}
