const CONTENT_EN = {
  titel: "12ᵗʰ DHI Conference",
  hou_de_datum_vrij: "Save the date!",
  datum: "6ᵗʰ to 10ᵗʰ of August 2025",
  locatie: "Ghent, Belgium",
  meer_info_zsm: "More info coming soon...",
  onderwerp: "Deaf Youth in History",
};

export default CONTENT_EN;
