import Content from "./components/Content";
import NavigationBar from "./components/NavigationBar";
import { LanguageProvider } from "./context/LanguageContext";
import { ThemeArea } from "./context/ThemeContext";

function App() {
  return (
    <ThemeArea>
      <LanguageProvider>
        <NavigationBar />
        <Content />
      </LanguageProvider>
    </ThemeArea>
  );
}

export default App;
