import React, { useContext } from "react";
import { ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";
import Flag from "react-flagkit";
import LanguageContext from "../context/LanguageContext";

export default function LanguageSelector() {
  const { language, handleSelectLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (event) => {
    handleSelectLanguage(event.target.value);
  };

  return (
    <div className="utility--item">
      <Select
        className="select--item"
        value={language}
        onChange={handleLanguageChange}
      >
        <MenuItem className="menu--item" value="en">
          <ListItemIcon className="list-icon--item">
            <Flag className="flag" country="GB" />
          </ListItemIcon>
          <ListItemText className="list-text--item" primary="English" />
        </MenuItem>
        <MenuItem className="menu--item" value="nl">
          <ListItemIcon className="list-icon--item">
            <Flag className="flag" country="NL" />
          </ListItemIcon>
          <ListItemText className="list-text--item" primary="Nederlands" />
        </MenuItem>
        <MenuItem className="menu--item" value="fr">
          <ListItemIcon className="list-icon--item">
            <Flag className="flag" country="FR" />
          </ListItemIcon>
          <ListItemText className="list-text--item" primary="Français" />
        </MenuItem>
      </Select>
    </div>
  );
}
