import React, { useContext } from "react";
import LanguageContext from "../context/LanguageContext";
import CONTENT_EN from "../data/content_en";
import CONTENT_FR from "../data/content_fr";
import CONTENT_NL from "../data/content_nl";
import Countdown from "./Countdown";

export default function Content() {
  const { language } = useContext(LanguageContext);

  const getContent = (language) => {
    switch (language) {
      case "fr":
        return CONTENT_FR;
      case "nl":
        return CONTENT_NL;
      default:
        return CONTENT_EN;
    }
  };

  const content = getContent(language);

  const targetDate = new Date("2025-08-06T10:00:00");

  return (
    <div className="App">
      <div className="teaser--section">
        <div className="content">
          <h1>{content.titel}</h1>
          <h2>{content.onderwerp}</h2>
          <p>{content.locatie}</p>
        </div>
      </div>
      <Countdown targetDate={targetDate} />
      <div className="more-info--section">
        <div className="content">
          <p>{content.meer_info_zsm}</p>
        </div>
      </div>
    </div>
  );
}
