const CONTENT_NL = {
  titel: "12ᵉ DHI Conferentie",
  hou_de_datum_vrij: "Hou de datum vrij!",
  datum: "6 tot en met 10 augustus 2025",
  locatie: "Gent, België",
  meer_info_zsm: "Meer info volgt later...",
  onderwerp: "Dove Jeugd in de Geschiedenis",
};

export default CONTENT_NL;
