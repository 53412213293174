const CONTENT_FR = {
  titel: "12ième Conférence DHI",
  hou_de_datum_vrij: "Réservez la date!",
  datum: "Du 6 au 10 août 2025",
  locatie: "Gand, Belgique",
  meer_info_zsm: "Plus d'informations à venir...",
  onderwerp: "La Jeunesse Sourde dans l'Histoire",
};

export default CONTENT_FR;
